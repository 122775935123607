import React from 'react';
import App from './App';

import ReactDOM from "react-dom/client";

import {
  HashRouter,
  Routes,
  Route,
} from "react-router-dom";


import Home from './components/pages/Home';
import Products from './components/pages/Products';
import Kontakt from './components/pages/Kontakt';
import Gallery from './components/pages/Gallery';

const root = ReactDOM.createRoot(
  document.getElementById("root")
);


root.render(
  <HashRouter>
      <App></App>

  </HashRouter>
);

{/*    <Routes>
      <Route exact path="/"  element={<Home />} />
      <Route exact path='kontakt' element={<Kontakt />} />
      <Route exact path='products' element={<Products />} />
      <Route exact path='gallery' element={<Gallery />} />
    </Routes>*/}