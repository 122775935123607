import React from 'react';
import '../../App.css';
import ImageSlider from '../ImageSlider';
import { SliderData } from '../SliderData';


function Gallery() {
  return (
    <div className = "galleryBackground">
    <ImageSlider slides = {SliderData}></ImageSlider>
    </div>
  );
}
  
export default Gallery;
