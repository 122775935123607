import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <>
    <div className='cards'>
      <h1>Na čo sa môžete tešiť</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src = "./images/pifko.jpg"
              text='U nás čapujeme výhradne české pivo Pilsner Urquell'
              label='Pivo'
              
            />
            <CardItem
              src='./images/img-11.jpg'
              text='Príďte si oddýchnuť aj s vašimi deťmi'
              label='Preliezky'

            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='./images/img-5.jpg'
              text='Bezpečné odloženie vašich bicyklov'
              label='Pre cyklistov'

            />
            <CardItem
              src='./images/img-4.jpg'
              text='Premietanie športových zápasov a podujatí na plátne alebo TV'
              label='Šport'

            />
            {/* <CardItem
              src='./images/img-8.jpg'
              text='Ride through the Sahara Desert on a guided camel tour'
              label='Adrenaline'

            /> */}
          </ul>
        </div>
      </div>
    </div>
    </>
  );
}

export default Cards;
