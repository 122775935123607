import React, { Component } from "react";
import '../App.css';
import './HeroSection.css';

export default function HeroSection() {
  return (
    
    <div className='hero-container'>
      <h1>Otváracie hodiny: </h1>
      <div className="text">
        <table width="100%" id="otvaracie-hod">
          <tbody>
            <tr>
              <td>Pondelok</td><td id="td-spacer"/><td>11:00 - 22:00</td>
            </tr>
            <tr>
              <td>Utorok</td><td id="td-spacer"/><td>11:00 - 23:00</td>
            </tr>
            <tr>
              <td>Streda</td><td id="td-spacer"/><td>11:00 - 23:00</td>
            </tr>
            <tr>
              <td>Štvrtok</td><td id="td-spacer"/><td>11:00 - 23:00</td>
            </tr>
            <tr>
              <td>Piatok</td><td id="td-spacer"/><td>11:00 - 24:00</td>
            </tr>
            <tr>
              <td>Sobota</td><td id="td-spacer"/><td>10:00 - 24:00</td>
            </tr>
            <tr>
              <td>Nedeľa</td><td id="td-spacer"/><td>10:00 - 22:00</td>
            </tr>
          </tbody>
        </table>
      </div>
    
      <svg className="arrows">
          <path className="a1" d="M0 0 L30 32 L60 0"></path>
          <path className="a2" d="M0 20 L30 52 L60 20"></path>
          <path className="a3" d="M0 40 L30 72 L60 40"></path>
      </svg>
    </div>
  );
};


