import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function Footer() {
  const startYear = 2021
  const currentYear = new Date().getFullYear()

  return (
    <div className='footer-container'>
      <section className='social-media'>
        
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              <img src= './logo-transparent-bez-textu.png' width="120"/>
            </Link>
          </div>
          <div className="website-rights-wrap">
            <small className='website-rights'>FINIŠ © {new Date().getFullYear()}</small>
            <small className='website-rights'>Tel.č: <a className='website-rights' href="tel:0911151528">0911 151 528</a></small>
            <small className='website-rights'>Email: <a className='website-rights' href="mailto:finis.nitra@gmail.com">finis.nitra@gmail.com</a></small>
          </div>
          <div className='social-icons'>
            <Link
              className='social-icon-link facebook'
              to={{ pathname: "https://www.facebook.com/Finis.Nitra" }}
              target='_blank'
              aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </Link>
            <Link
              className='social-icon-link instagram'
              to={{ pathname: "https://www.instagram.com/nitrafinis" }}
              target="_blank" 
              aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
