import React from 'react';
import Navbar from './components/Navbar';
import './App.css';


import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Home from './components/pages/Home';
import Products from './components/pages/Products';
import Kontakt from './components/pages/Kontakt';
import Gallery from './components/pages/Gallery';
import { Outlet, Link } from "react-router-dom";

import HeroSection from './/components/HeroSection';
import Footer from './components/Footer';
import { HashRouter } from 'react-router-dom';

{/*
function App() {
  return (
    
    <div>
    <h1>main</h1>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route exact path="/"  element={<Home/>}/>
          <Route exact path='kontakt' element={<Kontakt/>} />
          <Route exact path='products' element={<Products/>} />
          <Route exact path='gallery' element={<Gallery/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
*/}

{/*export default function App() {
  return (
    <div>
      <h1>Bookkeeper!</h1>

      <Link to="/kontakt">Expenses</Link>
      <Outlet />
      
    </div>
  );
}
*/}
export default function App() {
  return (

    <>
      <Navbar></Navbar>
      <Routes>
        <Route exact path="/"  element={<Home />} />
        <Route exact path='kontakt' element={<Kontakt />} />
        <Route exact path='products' element={<Products />} />
        <Route exact path='gallery' element={<Gallery />} />
      </Routes>
      <Footer></Footer>
    </>

      

  );
}
