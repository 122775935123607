import React, { Component } from "react";
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import { Outlet, Link } from "react-router-dom";
import Navbar from "../Navbar";


export default function Home() {
  return (
    <div className="flex-wrapper">
      <HeroSection></HeroSection>
      <Cards></Cards>

      
    </div>
  );
}

