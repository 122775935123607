export const SliderData = [
    {
        image: 'https://scontent.fbts3-1.fna.fbcdn.net/v/t1.15752-9/172305659_142895441111936_2117264674138350742_n.jpg?_nc_cat=105&ccb=1-3&_nc_sid=ae9488&_nc_ohc=xZLpwCETC_wAX-hiEFy&_nc_ht=scontent.fbts3-1.fna&oh=91f1c09fd87da41625ef1be2123c0d17&oe=609EAA8F'
    },

    {
        image: 'https://scontent.fbts3-1.fna.fbcdn.net/v/t1.15752-9/171939191_2515735868721985_3870412430469207647_n.jpg?_nc_cat=104&ccb=1-3&_nc_sid=ae9488&_nc_ohc=OB2XE3y990YAX_24lg-&_nc_ht=scontent.fbts3-1.fna&oh=6548771d3445f58e54db1887f494d87f&oe=609EE944'
    },

    {
        image: 'https://scontent.fbts3-1.fna.fbcdn.net/v/t1.15752-9/172531289_305732457555033_2951741146724122881_n.jpg?_nc_cat=106&ccb=1-3&_nc_sid=ae9488&_nc_ohc=u0L0Q9h-VnIAX_8fB7h&_nc_ht=scontent.fbts3-1.fna&oh=40fd138f50ba8fe5a0f96263e723f8cc&oe=609E200D'
    },

    {
        image: 'https://scontent.fbts3-1.fna.fbcdn.net/v/t1.15752-9/172039670_355417172568076_4385722301720024696_n.jpg?_nc_cat=109&ccb=1-3&_nc_sid=ae9488&_nc_ohc=EGZTclxsxO4AX87a59B&_nc_ht=scontent.fbts3-1.fna&oh=fa19191e830bcf533d59212fb48b4289&oe=609E6949'
    },

    {
        image: 'https://scontent.fbts3-1.fna.fbcdn.net/v/t1.15752-9/163151349_816643005593932_30735677646513156_n.jpg?_nc_cat=102&ccb=1-3&_nc_sid=ae9488&_nc_ohc=a8SlSpKZGDYAX87Z9MK&_nc_ht=scontent.fbts3-1.fna&oh=22a3a4f23a26ae64aa95573b296617d6&oe=607885DF'
    },
]