import React from 'react';
import '../../App.css';
import Footer from '../Footer';

function Products() {
  return (
    <>
    <div className="products">
    <h1>IN DEVELOPMENT</h1>
    <br/>
    
    </div>
    <Footer/>
    </>
  )
}

export default Products;
