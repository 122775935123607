import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import '../Kontakt.css';





function Kontakt() {
  return (
    <>

      <div className="subpage-container">
      <h1>KONTAKT</h1>
      <p>Tel.č: <a id="mail-link" href="tel:0911151528">0911 151 528</a></p>
      <p>Email: <a id="mail-link" href="mailto:finis.nitra@gmail.com">finis.nitra@gmail.com</a></p>
      <p>Adresa: Nábrežie Mládeže 2B</p>
      <iframe id="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2653.8754421731396!2d18.091626996279906!3d48.30524696748792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa0345fe0c29fb1e1!2zRklOScWg!5e0!3m2!1sen!2ssk!4v1658784019009!5m2!1sen!2ssk"   allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>
    </>
  );
}

export default Kontakt;
